import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
// import UserRoutes from './user';

const routes = [
  {
    path: '/',
    component: () => import('../layouts/Master.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home.vue'),
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/About.vue'),
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Contact.vue'),
      },
      {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/PrivacyPolicy.vue'),
      },
      {
        path: '/terms-and-condition',
        name: 'Terms Conditions',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/TermsAndCondition.vue'),
      },
      {
        path: '/faq',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Faq.vue'),
      },
      {
        path: '/refund-return-policy',
        name: 'return Policy',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ReturnPolicy.vue'),
      },
      {
        path: '/shipping-delivery',
        name: 'Shipping & Delivery',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ShippingDelivery.vue'),
      },
      {
        path: '/products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/product/Product'),
      },
      {
        path: '/cart',
        name: 'Cart',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/shoping_cart/ShopingCart'),
      },
      {
        path: '/checkout',
        name: 'Checkout',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/shoping_cart/Checkout'),
      },
      {
        path: '/thank/you/message',
        name: 'Thank you message',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ThankYouMessageWithInvoice'),
      },
      {
        path: '/product/:slug',
        name: 'Product Details',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/product/SingleProduct'),
      },
      {
        path: '/category/:slug',
        name: 'Category Product',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/product/Product'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/Login'),
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/Registration'),
      },
      {
        path: '/forget/password',
        name: 'Forget Password',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/ForgetPassword'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Dashboard'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/user',
        component: () => import('../views/user/layouts/Master.vue'),
        children: [
          {
            path: 'order/list',
            name: 'orderList',
            component: () => import(/* webpackChunkName: "orderList" */ '../views/user/OrderList.vue'),
            meta: {
              auth: true,
            }
          },
          {
            path: 'dashboard',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/Dashboard.vue'),
            meta: {
              auth: true,
            }
          },
        ]
      },
    ],
  },
  
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {    
    // console.log('record', record.meta.auth)
    if (store.state.auth.user) {
      console.log('store.state.auth.user', store.state.auth);
      
      if (!store.state.auth.loggedIn &&  !localStorage.getItem('api_token')) {
        next({
          name: 'login'
        });
      } else {
        next();
      }
    } else {
      next({
        name: 'login'
      });
    }
  } else {
    next();
  }
});

export default router;
